<template>
  <div v-if="loaded">
    <div class="columns">
      <div class="column is-two-thirds">
        <h1>
          <status-icon :inline="true" :status="caseForm.status" />
          {{ title | emptyMessage }}
        </h1>
        <case-tags :tags="caseForm.tags" />
      </div>
      <div class="column">
        <div class="buttons is-right">
          <div v-if="isCaseReleasable && !appraiserHasApi">
            <b-tooltip
              v-if="isCaseReleaseDisabled"
              :label="$t('UI.detail.releaseInfo')"
              :multilined="true"
              type="is-danger"
            >
              <b-button icon-left="check" type="is-info" :disabled="true"
                >{{ $t("UI.detail.release") }}
              </b-button>
            </b-tooltip>
            <b-button
              v-else
              icon-left="check"
              type="is-info"
              @click.prevent="releaseCase()"
              >{{ $t("UI.detail.release") }}
            </b-button>
          </div>
          <b-button
            v-if="appraiserHasDocument"
            icon-left="file-pdf"
            type="is-info"
            @click.prevent="getExpertForm()"
            >{{ $t("UI.detail.expertForm") }}
          </b-button>
          <div v-if="isCaseReleasable && appraiserHasApi">
            <b-tooltip
              v-if="isCaseHandoverDisabled || isCaseReleaseDisabled"
              :label="$t('UI.detail.handoverInfo')"
              :multilined="true"
              type="is-danger"
            >
              <b-button icon-left="check" type="is-info" :disabled="true"
                >{{ $t("UI.detail.handover") }}
              </b-button>
            </b-tooltip>
            <b-button
              v-else
              icon-left="check"
              type="is-info"
              @click.prevent="handoverCase()"
              >{{ $t("UI.detail.handover") }}
            </b-button>
          </div>

          <b-button
            v-if="isCaseEditable"
            icon-left="window-close"
            type="is-danger"
            @click.prevent="cancelCase()"
            >{{ $t("UI.detail.cancelCase") }}
          </b-button>
          <b-button
            v-if="isCaseDeletable"
            icon-left="trash-alt"
            type="is-danger"
            @click.prevent="deleteCase()"
            >{{ $t("UI.detail.delete") }}
          </b-button>
          <b-button
            v-if="isCaseEditable"
            :loading="poaLoading"
            icon-left="file-pdf"
            type="is-success"
            @click.prevent="addPOAForm()"
            >{{ $t("UI.detail.addPOA") }}
          </b-button>
          <b-button
            v-if="caseForm.status === Status.RELEASED && isCaseHandler"
            icon-left="calendar-check"
            type="is-info"
            @click.prevent="closeCase()"
            >{{ $t("UI.detail.close") }}
          </b-button>
          <b-button
            v-if="!isCaseEditable && isCaseHandler"
            icon-left="undo"
            type="is-info"
            @click.prevent="reopenCase()"
            >{{ $t("UI.detail.reopen") }}
          </b-button>
          <b-button
            v-if="
              !isCaseReleasable &&
              isCaseEditable &&
              isCaseHandler &&
              caseForm.fileSign === ''
            "
            icon-left="share-square"
            type="is-info"
            :loading="jlLoading"
            @click.prevent="sendToJl()"
            >{{ $t("UI.detail.sendToJL") }}
          </b-button>
          <b-button
            v-if="isCaseEditable"
            icon-left="pen"
            type="is-warning"
            @click.prevent="editCase()"
            >{{ $t("UI.general.edit") }}
          </b-button>
          <b-button icon-left="arrow-left" @click.prevent="backToOverview()">{{
            $t("UI.general.close")
          }}</b-button>
        </div>
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-two-thirds-desktop">
        <timato-form
          v-model="caseForm"
          :action="Action.DETAIL"
          :disabled="true"
        />
      </div>
      <div class="column">
        <file-upload
          :active="isCaseEditable"
          :customer-email-valid="customerEmailValid"
          :case-id="caseId"
        />
        <evaluation-details
          :case-id="caseForm.id"
          :file-number="caseForm.fileSign"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column is-two-thirds-desktop">
        <b-tabs class="block" destroy-on-hide>
          <b-tab-item :label="$i18n.t('UI.detail.comments')" icon="comments">
            <comments-view :case-id="caseId" :status="caseForm.status" />
          </b-tab-item>
          <b-tab-item :label="$i18n.t('UI.detail.history')" icon="history">
            <history-view :case-id="caseId" />
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
  <b-loading v-else v-model="loaded" :can-cancel="false" :is-full-page="true">
    <div class="box p-6 has-text-centered">
      <h1>{{ $t("UI.general.loading") }}</h1>
    </div>
  </b-loading>
</template>

<script lang="js">
import Vue from "vue";
import { CaseTags, TimatoForm } from "@/ui/components/common";
import {PoaData, UserDetails} from "@/common/dto";
import { NamedRoutes } from "@/ui/router";
import {Action, DamageType, DecisionEnum, Status} from "@/common/enums";
import { StatusIcon } from "@/ui/components/overview";
import i18n from "@/ui/i18n";
import { mapGetters } from "vuex";
import { userModuleMeta } from "@/ui/store/user";
import {
  AttachmentHttpClient,
  CasesHttpClient,
  ExternalIntegrationsHttpClient,
} from "@/common/http";
import { BuefyHelpers, DtoHelpers } from "@/common/helpers";
import { CommentsView } from "@/ui/components/detail/comments";
import moment from "moment";
import EvaluationDetails from "@/ui/components/detail/EvaluationDetails.vue";
import {
  ATTACHMENT_ADDED, ATTACHMENT_EXPERT_OPINION_ORDER_STATE_CHANGED,
  ATTACHMENT_SIGNED_POA_STATE_CHANGED, POA_SIGNED,
} from "@/common/events";
import { event_bus } from "@/main";
import { HistoryView } from "@/ui/components/detail/history";
import { FileUpload } from "@/ui/components/detail/attachments";

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

function emailIsValid(email) {
  // Check for null, undefined, empty string or whitespace-only string
  if (!email || email.trim() === "") {
    return false;
  }

  email = email.toString().trim();
  if (email.length > 254) {
    return false;
  }

  return emailRegex.test(email);
}
const ModalForm = {
  data() {
    return {
      workshopCommitment: false,
      insuranceCaseNumber: "",
    };
  },
  methods: {
    submit() {
      this.$emit('submit', {
        workshopCommitment: this.workshopCommitment,
        insuranceCaseNumber: this.insuranceCaseNumber
      });
      this.$emit('close');
    }
  },
  template: `
            <form action="">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Vollmacht erstellen</p>
                    </header>
                    <section class="modal-card-body">
                        <b-field label="Werkstattbindung">
                          <b-checkbox v-model="workshopCommitment">Ja</b-checkbox>
                        </b-field>
                    </section>
                    <footer class="modal-card-foot">
                        <b-button
                            label="Schließen"
                            @click="$emit('close')" />
                        <b-button
                            label="Erstellen"
                            type="is-primary"
                            @click="submit()" />
                    </footer>
                </div>
            </form>
        `,
};

export default Vue.extend({
  components: {
    HistoryView,
    EvaluationDetails,
    CommentsView,
    StatusIcon,
    FileUpload,
    TimatoForm,
    CaseTags,
  },
  data() {
    return {
      caseForm: {},
      errors: [],
      loaded: false,
      poaLoading: false,
      jlLoading: false,
      isCaseReleaseDisabled: false,
      isCaseHandoverDisabled: false,
      Action,
      Status,
    };
  },
  methods: {
    editCase: function () {
      this.$router.push({
        name: NamedRoutes.EDIT,
        params: { caseId: this.caseId },
      });
    },
    backToOverview: function () {
      this.$router.push({
        name: NamedRoutes.OVERVIEW,
      });
    },
    handoverCase: function () {
      const options = {
        title: i18n.t(`UI.dialog.handoverCaseAppraiser.title`),
        message: i18n.t(`UI.dialog.handoverCaseAppraiser.message`),
        type: "is-info",
        hasIcon: true,
        icon: "exclamation-circle",
        confirmText: i18n.t(`UI.dialog.handoverCaseAppraiser.confirmText`),
        cancelText: i18n.t(`UI.dialog.handoverCaseAppraiser.cancelText`),
        onConfirm: () => this.updateStatus(Status.HANDED_OVER_APPRAISER),
      };
      this.$buefy.dialog.confirm(options);
    },
    validateFormFields: function(extendedChecks = false) {
      const messages = [];
      if (
          (this.caseForm.injuredParty.lastName === "" ||
              this.caseForm.injuredParty.firstName === "") &&
          this.caseForm.injuredParty.company === ""
      ) {
        messages.push(i18n.t("UI.dialog.addForm.injuredNameMissing"));
      }
      if (
          this.caseForm.accidentDate === null ||
          !moment(this.caseForm.accidentDate).isValid()
      ) {
        messages.push(i18n.t("UI.dialog.addForm.accidentDateMissing"));
      }
      if (this.caseForm.accidentPlace === "") {
        messages.push(i18n.t("UI.dialog.addForm.accidentPlaceMissing"));
      }
      if(extendedChecks) {
        if (this.caseForm.vehicleLicensePlate === "") {
          messages.push(i18n.t("UI.dialog.addForm.licensePlateMissing"));
        }
        if (this.caseForm.injuredParty.street1 === "") {
          messages.push(i18n.t("UI.dialog.addForm.streetMissing"));
        }
        if (this.caseForm.injuredParty.zip === "") {
          messages.push(i18n.t("UI.dialog.addForm.zipMissing"));
        }
        if (this.caseForm.injuredParty.city === "") {
          messages.push(i18n.t("UI.dialog.addForm.cityMissing"));
        }
        if (this.caseForm.injuredVatDeduction === DecisionEnum.NONE) {
          messages.push(i18n.t("UI.dialog.addForm.vatDeductionMissing"));
        }
      }
      return messages;
    },
    getExpertForm: function () {
      const messages = this.validateFormFields(true);

      if (messages.length > 0) {
        const options = {
          title: i18n.t("UI.dialog.addForm.title"),
          message: `${messages.join(`<br />`)} <p> ${i18n.t(
              "UI.dialog.addForm.questionText"
          )}</p>`,
          confirmText: i18n.t("UI.dialog.addForm.confirmText"),
          type: "is-danger",
          hasIcon: true,
          icon: "exclamation-triangle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        };

        this.$buefy.dialog.alert(options);
      } else {
        new AttachmentHttpClient()
        .getExpertForm(this.caseForm.id)
        .then((data) => {
          if (data) {
            event_bus.$emit(ATTACHMENT_ADDED);
            BuefyHelpers.show_success_notification(
              this.$buefy,
              i18n.t("UI.message.success"),
              i18n.t("UI.message.expertForm")
            );
          }
        })
        .catch((err) => {
          BuefyHelpers.show_request_exception_notification(
            this.$buefy,
            i18n.t("UI.error.attachment.getExpertForm"),
            err.message
          );
        });
      }
    },
    releaseCase: function () {
      const key =
        this.caseForm.damageType === DamageType.OWN_FAULT
          ? "OwnFault"
          : "ThirdParty";
      const options = {
        title: i18n.t(`UI.dialog.releaseCase${key}.title`),
        message: i18n.t(`UI.dialog.releaseCase${key}.message`),
        type: "is-info",
        hasIcon: true,
        icon: "exclamation-circle",
        confirmText: i18n.t(`UI.dialog.releaseCase${key}.confirmText`),
        cancelText: i18n.t(`UI.dialog.releaseCase${key}.cancelText`),
        onConfirm: () => this.updateStatus(Status.RELEASED),
      };
      this.$buefy.dialog.confirm(options);
    },
    closeCase: function () {
      const options = {
        title: i18n.t("UI.dialog.closeCase.title"),
        message: i18n.t("UI.dialog.closeCase.message"),
        type: "is-info",
        hasIcon: true,
        icon: "exclamation-triangle",
        confirmText: i18n.t("UI.dialog.closeCase.confirmText"),
        cancelText: i18n.t("UI.dialog.closeCase.cancelText"),
        onConfirm: () => this.updateStatus(Status.CLOSED),
      };
      this.$buefy.dialog.confirm(options);
    },
    deleteCase: function () {
      const options = {
        title: i18n.t("UI.dialog.deleteCase.title"),
        message: i18n.t("UI.dialog.deleteCase.message"),
        type: "is-danger",
        hasIcon: true,
        icon: "exclamation-triangle",
        confirmText: i18n.t("UI.dialog.deleteCase.confirmText"),
        cancelText: i18n.t("UI.dialog.deleteCase.cancelText"),
        onConfirm: () => {
          this.updateStatus(Status.HIDDEN, true);
        },
      };
      this.$buefy.dialog.confirm(options);
    },
    reopenCase: function () {
      this.updateStatus(Status.RELEASED);
    },
    cancelCase: function () {
      if (this.caseForm.additionalInfos) {
        const options = {
          title: i18n.t("UI.dialog.cancelCase.title"),
          message: i18n.t("UI.dialog.cancelCase.message"),
          type: "is-danger",
          hasIcon: true,
          icon: "exclamation-triangle",
          confirmText: i18n.t("UI.dialog.cancelCase.confirmText"),
          cancelText: i18n.t("UI.dialog.cancelCase.cancelText"),
          onConfirm: () => {
            this.updateStatus(Status.CANCELLED);
          },
        };
        this.$buefy.dialog.confirm(options);
      } else {
        const options = {
          title: i18n.t("UI.dialog.cancelCaseNoInfos.title"),
          message: i18n.t("UI.dialog.cancelCaseNoInfos.message"),
          confirmText: i18n.t("UI.dialog.cancelCaseNoInfos.confirmText"),
          type: "is-danger",
          hasIcon: true,
          icon: "exclamation-triangle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        };

        this.$buefy.dialog.alert(options);
      }
    },
    updateStatus: function (status, redirectToOverview = false) {
      new CasesHttpClient()
        .updateStatus(this.caseForm.id, status)
        .then((data) => {
          if (data) {
            if (redirectToOverview) {
              this.$router.push({
                name: NamedRoutes.OVERVIEW,
              });
            } else {
              this.updateCase();
            }
          }
        })
        .catch((err) => {
          BuefyHelpers.show_request_exception_notification(
            this.$buefy,
            i18n.t("UI.error.status.update"),
            err.message
          );
        });
    },
    createPOAForm: function (poaData) {
      this.poaLoading = true;
      BuefyHelpers.show_success_notification(
        this.$buefy,
        i18n.t("UI.message.success"),
        i18n.t("UI.message.createPoa")
      );
      new AttachmentHttpClient()
        .createPoa(this.caseForm.id, poaData)
        .then((data) => {
          if (data) {
            event_bus.$emit(ATTACHMENT_ADDED);
            BuefyHelpers.show_success_notification(
              this.$buefy,
              i18n.t("UI.message.success"),
              i18n.t("UI.message.addedPoa")
            );
          }
        })
        .catch((err) => {
          this.poaLoading = false;
          BuefyHelpers.show_request_exception_notification(
            this.$buefy,
            i18n.t("UI.error.attachment.createPoa"),
            err.message
          );
        })
        .finally(() => {
          this.poaLoading = false;
        });
    },
    sendToJl: function () {
      this.jlLoading = true;
      BuefyHelpers.show_success_notification(
        this.$buefy,
        i18n.t("UI.message.success"),
        i18n.t("UI.message.sendToJL")
      );
      new ExternalIntegrationsHttpClient()
        .sendCaseToJLawyer(this.caseForm.id)
        .then(() => {
          BuefyHelpers.show_success_notification(
            this.$buefy,
            i18n.t("UI.message.success"),
            i18n.t("UI.message.sendedToJL")
          );
          this.updateCase();
        })
        .catch((err) => {
          this.poaLoading = false;
          BuefyHelpers.show_request_exception_notification(
            this.$buefy,
            i18n.t("UI.error.attachment.sendToJL"),
            err.message
          );
        })
        .finally(() => {
          this.jlLoading = false;
        });
    },
    addPOAForm: function () {
      const messages = this.validateFormFields();

      if (messages.length > 0) {
        const options = {
          title: i18n.t("UI.dialog.addForm.title"),
          message: `${messages.join(`<br />`)} <p> ${i18n.t(
            "UI.dialog.addForm.questionText"
          )}</p>`,
          confirmText: i18n.t("UI.dialog.addForm.confirmText"),
          type: "is-danger",
          hasIcon: true,
          icon: "exclamation-triangle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
        };

        this.$buefy.dialog.alert(options);
      } else {
        if (this.caseForm.caseHandler === "kaskoscout") {
          this.$buefy.modal.open({
            parent: this,
            component: ModalForm,
            hasModalCard: true,
            customClass: "custom-class custom-class-2",
            trapFocus: true,
            events: {
              'submit': result => {
                const workshopCommitment = result.workshopCommitment ? DecisionEnum.YES : DecisionEnum.NO;
                this.createPOAForm(new PoaData(workshopCommitment));
              }
            }
          });
        } else {
          this.createPOAForm(new PoaData());
        }
      }
    },
    updateCase: async function () {
      await new CasesHttpClient()
        .loadCase(this.caseId)
        .then((res) => {
          this.caseForm = res;
          this.caseForm.accidentDate = moment(
            this.caseForm.accidentDate
          ).toDate();
          this.caseForm.repairDate = moment(this.caseForm.repairDate).toDate();
        })
        .catch((err) => {
          if (err.response.status == 403) {
            BuefyHelpers.show_request_exception_notification(
              this.$buefy,
              i18n.t("UI.error.cases.loading"),
              i18n.t("UI.error.general.notAllowed")
            );
            this.$router.push({
              name: NamedRoutes.OVERVIEW,
            });
          } else {
            BuefyHelpers.show_request_exception_notification(
              this.$buefy,
              i18n.t("UI.error.cases.loading"),
              err.message
            );
          }
        })
        .finally(() => {
          this.loaded = true;
        });
    },
  },
  computed: {
    ...mapGetters({
      details: userModuleMeta.getters.getDetails,
    }),
    caseId: function () {
      return this.$route.params.caseId;
    },
    title: function () {
      return DtoHelpers.generate_case_title(this.$data.caseForm);
    },
    isCaseHandler: function () {
      return (
          UserDetails.isAdminLocation(this.details) ||
          UserDetails.isCaseHandler(this.details)
      );
    },
    isAdmin: function () {
      return UserDetails.isAdminLocation(this.details);
    },
    isCaseReleasable: function () {
      return this.caseForm.status === Status.WORK_IN_PROGRESS;
    },
    isCaseEditable: function () {
      return (
        this.caseForm.status !== Status.CANCELLED &&
        this.caseForm.status !== Status.CLOSED
      );
    },
    isCaseDeletable: function () {
      return (
        this.isAdmin ||
        ((UserDetails.isAdmin(this.details) || this.isCaseHandler) && this.isCaseReleasable)
      );
    },
    customerEmailValid: function () {
      return emailIsValid(this.caseForm.injuredParty.email);
    },
    appraiserHasApi: function () {
      return this.caseForm != null && this.caseForm.expertOfficeCode != null && this.caseForm.expertOfficeCode.hasApiIntegration;
    },
    appraiserHasDocument: function () {
      return this.caseForm != null && this.caseForm.expertOfficeCode != null && (this.caseForm.expertOfficeCode.hasDocument
          || this.caseForm.expertOfficeCode.hasApiIntegration);
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    event_bus.$on(ATTACHMENT_SIGNED_POA_STATE_CHANGED, (res) => {
      this.isCaseReleaseDisabled = !res;
    });
    event_bus.$on(ATTACHMENT_EXPERT_OPINION_ORDER_STATE_CHANGED, (res) => {
      this.isCaseHandoverDisabled = !res;
    });
    event_bus.$on(POA_SIGNED, (id) => {
      if(this.customerEmailValid) {
        const options = {
          title: i18n.t(`UI.dialog.sendPoaToCustomer.title`),
          message: i18n.t(`UI.dialog.sendPoaToCustomer.message`),
          type: "is-info",
          hasIcon: true,
          icon: "question-circle",
          confirmText: i18n.t(`UI.dialog.sendPoaToCustomer.confirmText`),
          cancelText: i18n.t(`UI.dialog.sendPoaToCustomer.cancelText`),
          onConfirm: () => new AttachmentHttpClient().sendDocumentToCustomer(id)
          .then(() => {
            BuefyHelpers.show_success_notification(
                this.$buefy,
                i18n.t("UI.message.success"),
                i18n.t("UI.message.documentSent")
            );
          })
          .catch((err) => {
            BuefyHelpers.show_request_exception_notification(
                this.$buefy,
                i18n.t("UI.error.attachment.sendDocument"),
                err.message
            );
          })       ,
        };
        this.$buefy.dialog.confirm(options);
      }
    });
    await this.updateCase();
  },
});
</script>
