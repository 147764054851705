<template>
  <div id="main-container">
    <div v-if="loading">
      <b-loading v-model="loading" :can-cancel="false" :is-full-page="true" />
    </div>
    <div v-else class="pdf-container">
      <div ref="viewer" class="pdf-viewer"></div>
      <div v-if="attachment.signable" class="buttons mr-4 mb-2 is-right">
        <b-button
          :disabled="!saveEnabled"
          type="is-success"
          @click.prevent="saveDocument()"
          >{{ $t("UI.general.save") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#container {
  width: 60rem;
  height: 100%;
  background-color: var(--view);
}

#main-container {
  background-color: #f6f8fa;
}
.pdf-container {
  height: 90vh;
}
.pdf-viewer {
  height: 100%;
  width: 100%;
}
</style>

<script lang="js">
import {AttachmentHttpClient} from "@/common/http";
import {BuefyHelpers} from "@/common/helpers";
import i18n from "@/ui/i18n";
import {AttachmentDTO, FormDataWithSignatureDTO} from "@/common/dto";
import {mapGetters} from "vuex";
import {configModuleMeta} from "@/ui/store/configuration";
import {AttachmentTag} from "@/common/enums";
import {ATTACHMENT_UPDATED, POA_SIGNED} from "@/common/events";
import WebViewer from "@pdftron/pdfjs-express";
import { event_bus } from "@/main";

let webViewerInstance = null;

const parseXmlToSignatureDTOs = (xmlString) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, 'text/xml');

  // Select all <ink> elements (assuming they represent signatures)
  const inkAnnotations = Array.from(xmlDoc.querySelectorAll('ink'));

  return inkAnnotations.map((ink) => {
    // Extract position from ink rect attribute
    const rect = ink.getAttribute('rect').split(',');
    const position = {
      llx: parseFloat(rect[0]),
      lly: parseFloat(rect[1]),
      urx: parseFloat(rect[2]),
      ury: parseFloat(rect[3]),
    };

    // Extract page number
    const page = parseInt(ink.getAttribute('page'), 10);

    // Get all gesture elements
    const gestureElements = Array.from(ink.querySelectorAll('gesture'));

    // Map all gesture elements to arrays of coordinates
    const signatureCoordinates = gestureElements.map((gestureEl) => {
      return gestureEl.textContent
      .split(';')
      .filter(Boolean)
      .map((point) => {
        const [x, y] = point.split(',').map(parseFloat);
        return { x, y };
      });
    }).filter(coords => coords.length > 0); // Remove empty strokes

    // Skip if no valid signature coordinates were found
    if (signatureCoordinates.length === 0) {
      return null;
    }

    const signatureId = ink.getAttribute('name');

    return {
      fieldCoordinates: position,
      page,
      signatureCoordinates,
      signatureId,
    };
  }).filter(Boolean); // Remove any null entries
};

const parseXmlToFields = (xmlString) => {
  // Add UTF-8 declaration if it's missing
  if (!xmlString.includes('<?xml')) {
    xmlString = '<?xml version="1.0" encoding="UTF-8"?>' + xmlString;
  }

  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, 'text/xml');

  const fields = Array.from(xmlDoc.querySelectorAll('field'));
  const fieldsMap = {};
  fields.forEach((field) => {
    const name = field.getAttribute('name');
    let value = field.querySelector('value') ? field.querySelector('value').textContent : '';

    try {
      const bytes = new Uint8Array(value.split('').map(c => c.charCodeAt(0)));
      value = new TextDecoder('utf-8').decode(bytes);
    } catch (e) {
      try {
        value = decodeURIComponent(encodeURIComponent(value));
      } catch (e2) {
        console.warn('Could not decode value:', value);
      }
    }

    fieldsMap[name] = value;
  });

  return fieldsMap;
};

export default {
  name: "ShowPdf",
  props: {
    attachment: AttachmentDTO,
  },
  data() {
    return {
      loading: true,
      saveEnabled: false,
      instance: null,
      overwrites: {},
    };
  },
  methods: {
    async saveDocument() {
      if (webViewerInstance) {
        const { annotationManager } = webViewerInstance.Core;
        const annotations = await annotationManager.exportAnnotations();
        if(annotations.includes('type="Sig"')) {
          const signatures = parseXmlToSignatureDTOs(annotations);
          if(this.attachment.tag === AttachmentTag.POA) {
            new AttachmentHttpClient()
            .signAttachment(this.attachment.attachmentId, signatures)
            .then((res) => {
              BuefyHelpers.show_success_notification(
                  this.$buefy,
                  i18n.t("UI.message.success"),
                  i18n.t("UI.message.attachmentUpdated", { fileName: res.fileName })
              );
              event_bus.$emit(ATTACHMENT_UPDATED);
              event_bus.$emit(POA_SIGNED, this.attachment.attachmentId);
            })
            .catch((err) => {
              BuefyHelpers.show_request_exception_notification(
                  this.$buefy,
                  i18n.t("UI.error.attachment.upload"),
                  err.message
              );
            })
            .finally(() => {
              this.$emit("close");
            });
          }
          if(this.attachment.tag === AttachmentTag.EXPERT_OPINION_ORDER) {
            const fields = parseXmlToFields(annotations);

            new AttachmentHttpClient()
            .fillFormAndAddSignatures(this.attachment.attachmentId, new FormDataWithSignatureDTO(fields, signatures))
            .then((res) => {
              BuefyHelpers.show_success_notification(
                  this.$buefy,
                  i18n.t("UI.message.success"),
                  i18n.t("UI.message.attachmentUpdated", { fileName: res.fileName })
              );
              event_bus.$emit(ATTACHMENT_UPDATED);
            })
            .catch((err) => {
              BuefyHelpers.show_request_exception_notification(
                  this.$buefy,
                  i18n.t("UI.error.attachment.upload"),
                  err.message
              );
            })
            .finally(() => {
              this.$emit("close");
            });
          }
        }
      }
    },
    async createViewer(pdfFile) {
      const buffer = await pdfFile.arrayBuffer();
      const blob = new Blob([buffer], { type: "application/pdf" });
      WebViewer(
          {
            path: "/pdfjsexpress",
            licenseKey: "B5pVy5MyRa2p9sNSwZbt",
          },
          this.$refs.viewer
      ).then((instance) => {
        webViewerInstance = instance;
        instance.UI.loadDocument(blob, { filename: this.attachment.fileName });

        const { documentViewer } = instance.Core;

        documentViewer.addEventListener('documentLoaded', () => {
          if(this.attachment.signable) {
            const {annotationManager} = instance.Core;
            annotationManager.addEventListener('annotationChanged', (annotations, action) => {
              if (annotations.some(annotation => annotation.Subject === 'Signature')) {
                this.saveEnabled = action === 'add' || action === 'modify';
              }
            });
            documentViewer.getAnnotationsLoadedPromise().then(() => {
              // iterate over fields
              const fieldManager = annotationManager.getFieldManager();
              fieldManager.forEachField(field => {
                if (this.overwrites[field.name]) {
                  field.setValue(this.overwrites[field.name]);
                }
              });
            });
          }
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      clientUrl: configModuleMeta.getters.getClientUri,
    }),
    filename() {
      if (this.attachment.tag !== AttachmentTag.NONE) {
        return i18n.t("UI.enum.attachment_tag." + this.attachment.tag);
      }
      return this.attachment.fileName;
    },
  },
  beforeDestroy() {
    if (webViewerInstance) {
      webViewerInstance.dispose();
    }
  },
  created() {
    new AttachmentHttpClient()
      .downloadAttachment(this.attachment.attachmentId)
      .then((file) => {
        new AttachmentHttpClient()
        .getDocumentOverwrites(this.attachment.attachmentId)
        .then((res) => {
          this.overwrites = res;
          this.createViewer(file);
        })
      })
      .catch((err) => {
        BuefyHelpers.show_request_exception_notification(
          this.$buefy,
          i18n.t("UI.error.attachment.download"),
          err.message
        );
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
